import { PropsWithChildren } from "react";

const LayoutEmpty: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-full">
      <main className="flex flex-1 overflow-auto min-h-full min-w-full">{children}</main>
    </div>
  );
};

export default LayoutEmpty;
