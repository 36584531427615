import { useSelector } from "react-redux";
import { AppHead } from "@/components/generic/app/AppHead";
import { Redirect } from "@/components/generic/navigation/Redirect";
import LayoutEmpty from "@/components/layout/LayoutEmpty";
import { withOptionalUser, WithUserProps } from "@/components/wrappers/WithUser";
import { authSelectors } from "@/core/store/redux/auth/selectors";

const IndexPage: React.FC = () => {
  const user = useSelector(authSelectors.user);
  let redirect = "/onboarding/start";
  if (user) {
    redirect = "/dashboard"; // change with dashboard
  }

  return (
    <>
      <AppHead title="Homepage" />
      <LayoutEmpty>
        <Redirect to={redirect} loading />
      </LayoutEmpty>
    </>
  );
};

export default withOptionalUser(IndexPage);
