import { useRouter } from "next/navigation";
import React, { useEffect } from "react";

import { addHashToUrl, HashParams } from "@/core/utils/urls/hash";

import LoadingIndicator from "../ui/LoadingIndicator";

export enum RedirectType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}
interface Props {
  to: string;
  toHash?: HashParams;
  type?: RedirectType;
  loading?: boolean;
  replace?: boolean;
}
export const Redirect: React.FC<Props & React.PropsWithChildren> = ({
  to,
  toHash,
  type = RedirectType.INTERNAL,
  children,
  loading,
  replace,
}) => {
  const router = useRouter();
  const destination = toHash ? addHashToUrl(to, toHash) : to;
  useEffect(() => {
    if (type == RedirectType.EXTERNAL) {
      window.location.href = destination;
    } else {
      if (replace) router.replace(destination);
      else router.push(destination);
    }
  }, [destination, type, replace]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center m-auto w-full max-w-xl text-center p-6">
        <LoadingIndicator />
        {children}
      </div>
    );
  }
  return children;
};
