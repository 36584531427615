import { useSelector } from "react-redux";
import { authSelectors } from "@/core/store/redux/auth/selectors";
import { User, UserStatusType } from "@/core/store/redux/auth/types";

import { Redirect } from "../generic/navigation/Redirect";

export type WithUserProps = {
  user: User;
};

type WithUserExcludedProps<P> = Pick<P, Exclude<keyof P, keyof WithUserProps>>;

type WrappedComponent = React.FC<WithUserProps & any>;

type WithWrapperExtraProps = {
  PendingApprovalComponent?: React.ReactElement;
};

type WithWrapperProps = {
  Component: WrappedComponent;
} & WithWrapperExtraProps;

export function withHasUser<P extends WithWrapperProps>(Component: React.FC<P>): React.FC<WithUserExcludedProps<P>> {
  function WithWrapper(props: any): JSX.Element {
    const user = useSelector(authSelectors.user);
    if (!user) return <Redirect to="/auth/login" />;
    return <Component {...props} />;
  }
  return WithWrapper;
}

export function withOptionalUser<P extends WithWrapperProps>(
  Component: React.FC<P>,
  ExtraProps?: WithWrapperExtraProps,
): React.FC<WithUserExcludedProps<P>> {
  function WithWrapper(props: any): JSX.Element {
    const user = useSelector(authSelectors.user);
    return <Component {...props} user={user} />;
  }
  return WithWrapper;
}

export function withHasConfirmedUser<P extends WithWrapperProps>(
  Component: React.FC<P>,
  ExtraProps?: WithWrapperExtraProps,
): React.FC<WithUserExcludedProps<P>> {
  function WithWrapper(props: any): JSX.Element {
    const user = useSelector(authSelectors.user);
    if (!user) return <Redirect to="/auth/login" />;
    if (user.status == UserStatusType.PENDING_CONFIRMATION) {
      return <Redirect to="/auth/confirm-email/pending" />;
    } else if (user.status == UserStatusType.SUSPENDED) {
      return <Redirect to="/account/suspended" />;
    } else if (user.status == UserStatusType.PENDING_APPROVAL) {
      if (ExtraProps?.PendingApprovalComponent) {
        return ExtraProps.PendingApprovalComponent;
      }
    }
    return <Component {...props} />;
  }
  return WithWrapper;
}

export function withHasUnConfirmedUser<P extends WithWrapperProps>(
  Component: React.FC<P>,
  ExtraProps?: WithWrapperExtraProps,
): React.FC<WithUserExcludedProps<P>> {
  function WithWrapper(props: any): JSX.Element {
    const user = useSelector(authSelectors.user);
    if (!user) return <Redirect to="/auth/login" />;
    if (user.status != UserStatusType.PENDING_CONFIRMATION) {
      return <Redirect to="/" />;
    }
    return <Component {...props} />;
  }
  return WithWrapper;
}
